import React, { useEffect, useState } from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import Api from "../../../utils/Api";
import {
  Page,
  PageList,
  Header,
  QuestionsCount,
  QuestionsProgress,
} from "./PagesStyled";
import { Container } from "../../App/AppStyled";
import { FaCaretLeft } from "react-icons/fa";
import { Trans } from "react-i18next";

const Pages = () => {
  let { url } = useRouteMatch();
  let { childId, childHash, bookId } = useParams();
  const [book, setBook] = useState(null);

  useEffect(() => {
    const fetchBook = async () => {
      const { data } = await Api.get(`api/book/${bookId}?childId=${childId}`);

      setBook(data.book);
    };

    fetchBook();
  }, [bookId, childId]);

  return (
    <>
      <Container main>
        {book && (
          <>
            <Header>
              <div>
                <Link to={`/child/${childId}/${childHash}`}>
                  <FaCaretLeft /> <Trans>Back</Trans>
                </Link>
              </div>
              <h2>
                <Trans>Module</Trans> {book.number}
              </h2>
              <div></div>
            </Header>

            {book.pages.length ? (
              <PageList>
                {book.pages.map((page) => (
                  <Page key={page.id} color={page.color}>
                    <Link to={`${url}/page/${page.id}`}>{page.name}</Link>
                    {page.questions ? (
                      <QuestionsCount color={page.color}>
                        <p>
                          {page.questions.fill} / {page.questions.all}
                        </p>
                        <QuestionsProgress
                          value={
                            (page.questions.fill / page.questions.all) * 100 ||
                            0
                          }
                        />
                      </QuestionsCount>
                    ) : (
                      <QuestionsCount color={page.color}>
                        <p>0/0</p>
                        <QuestionsProgress value={0} />
                      </QuestionsCount>
                    )}
                  </Page>
                ))}
              </PageList>
            ) : (
              <p style={{ textAlign: "center" }}>Empty</p>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default Pages;
