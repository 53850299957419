import styled, { css } from "styled-components";

export const QuestionList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 10px;

  h3 {
    color: silver;
    font-weight: 400;
  }
`;

export const QuestionWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;

  h3 {
    margin-bottom: 4px;
    font-size: 12px;
  }

  ${(props) =>
    props.full &&
    css`
      grid-column-end: span 5;
    `}

  ${(props) =>
    props.center &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export const Question = styled.div`
  background: #f3f3f3;
  padding: 10px;
  border-radius: 2px;

  h4 {
    color: #3a3a3a;
    text-transform: uppercase;
    font-weight: bold;
  }

  a {
    display: block;
    font-size: 50px;
    font-weight: bold;
    padding: 10%;
    text-align: center;
    background: orange;
  }

  input {
    width: 100%;
    /* border: 1px solid #d2d2d2; */
    text-align: center;
    padding: 10px;
    outline: 0;
    background: #fdfdfd;
    border-radius: 5px;
    appearance: none;
    border: 0;
  }

  h3 {
    text-align: center;
    margin-top: 0;
    color: ${(props) => props.color};
    font-weight: 400;
  }

  ul {
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 20px 10px;

    li {
      border-bottom: 1px solid #dedede;
      padding: 10px;
    }

    li:last-child {
      border-bottom: 0;
    }
  }

  ${(props) =>
    props.wrong &&
    css`
      border: 2px solid #d85656;
    `}

  ${(props) =>
    props.valid &&
    css`
      border: 2px solid #56d866;
    `}
`;

export const Page = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 10px #0000002b;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin: 10px 0;
  }

  a {
    background: white;
    color: #000;
    padding: 5px 10px;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    margin-right: 3px;

    &.disabled {
      background: #dcdcdc;
      color: #fff;
      cursor: not-allowed;
    }
  }
`;

export const GroupTitle = styled.h2`
  text-transform: uppercase;
`;

export const Answers = styled.div`
  text-align: center;
  padding: 10px;
  margin-top: 20px;

  p {
    margin: 0;
    padding: 4px;
  }
`;

export const AnswersProgress = styled.div`
  background: #f3f3f3;
  position: relative;
  height: 10px;
  border-radius: 2px;
  overflow: hidden;

  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${(props) => props.value}%;
    background: #ff8400;
  }
`;

export const TableStyled = styled.table`
  border: 1px solid silver;
  border-collapse: collapse;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;

  td {
    border: 1px solid silver;
    padding: 5px 0;
    background: #fff;
  }

  input {
    padding: 0;
    border-radius: 0;
    border: 0;
    background: transparent;
  }

  .title-top {
    background: #e6e6e6;
    padding: 6px 0;
  }
  .title-left {
    background: #e6e6e6;
    padding: 6px;
  }
`;

export const SpeedGroup = styled.div`
  padding: 10px;

  div {
    margin-bottom: 10px;

    input {
      width: 80%;
    }

    svg {
      margin-right: 10px;
    }
  }
`;
