import { darken, transparentize } from "polished";

const BackgroundImage = (color, image, disableGradient = false) => {
  const color1 = transparentize(0.6, darken(0, color));
  const color2 = transparentize(0.3, darken(0.5, color));

  if (disableGradient) {
    return `linear-gradient(${color1}, ${color1}), url(${image})`;
  }
  return `linear-gradient(${color1}, ${color2}), url(${image})`;
};

export default BackgroundImage;
