import styled from "styled-components";
import school from "../../../img/school.png";
import { darken, transparentize } from "polished";

const imageBackground = (color, image, hover = false) => {
  const color1 = transparentize(hover ? 0.4 : 0.6, darken(0, color));
  const color2 = transparentize(0.3, darken(0.5, color));

  return `linear-gradient(${color1}, ${color2}), url(${image})`;
};

export const BookList = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
`;

export const Book = styled.div`
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.15);
  border-radius: 2px;
  overflow: hidden;

  a {
    display: block;
    font-size: 50px;
    font-weight: bold;
    padding: 20%;
    text-align: center;

    background: ${(props) => props.color};
    background-image: ${(props) => imageBackground(props.color, school)};
    background-size: cover;
    color: #fff;

    :hover {
    }
  }

  h4 {
    text-align: center;
    margin: 0;
    background: ${(props) => props.color};
    padding: 8px;
    color: #fff;
  }
`;

export const AdditionalsButton = styled.a`
  display: block;
  margin: 15px auto;
  text-align: center;
  text-transform: uppercase;
  background: #f3c52d;
  font-weight: bold;
  color: #fff;
  padding: 15px;
  max-width: 200px;
  border-bottom: 8px solid #ffd753;
  border-radius: 2px;
`;
