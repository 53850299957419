import React, { useEffect, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import { useParams, Link, useHistory } from "react-router-dom";
import Api from "../../../utils/Api";
import {
  Page,
  Question,
  QuestionWrapper,
  QuestionList,
  Header,
  GroupTitle,
  Answers,
  AnswersProgress,
  TableStyled,
  SpeedGroup,
} from "./QuestionsStyled";
import { Container } from "../../App/AppStyled";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { socket } from "../../../services/io";
import { debounce } from "lodash";
import Abacus from "./Abacus/Abacus";
import { GoGraph } from "react-icons/go";
import {
  FaCheckCircle,
  FaInfoCircle,
  FaCalendarAlt,
  FaClock,
} from "react-icons/fa";

import { BiRefresh } from "react-icons/bi";
import { Trans, useTranslation } from "react-i18next";

const AbacusWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;

  svg {
    display: block;
  }
`;

const AbacusCol = styled.div`
  width: 20%;
  margin: 0 10px;
`;

const AbacusSvg = ({ type }) => {
  if (type === "line") {
    return (
      <svg viewBox={`0 20 300 10`} style={{ transform: `scaleX(3)` }}>
        <line x1="0" x2="300" y1="20" y2="20" stroke="black" strokeWidth="20" />
      </svg>
    );
  } else if (type === "empty") {
    return (
      <svg viewBox={`0 20 300 260`}>
        <polygon
          stroke="black"
          points="300,150 225,280 75,280 0,150 75,20 225,20"
          fill="orange"
        ></polygon>
      </svg>
    );
  } else if (type === "dot") {
    return <svg viewBox={`0 20 300 260`}></svg>;
  }
};

const QuestionAbacus = ({ data }) => {
  const generateData = () => {
    let result = [];
    let numbers = String(data.dataSolution).split("");

    numbers.forEach((number) => {
      let row = [];
      number = parseInt(number);

      if (number - 5 < 0) {
        row.push("dot");
      } else {
        row.push("empty");
      }

      row.push("line");

      let n = number >= 5 ? number - 5 : number;
      for (let i = 0; i < 5; i++) {
        row.push(i >= n ? "dot" : "empty");
      }

      result.push(row);
    });

    return result;
  };

  return (
    <>
      <h4>
        <Trans>Abacus</Trans>
      </h4>
      <AbacusWrapper>
        {generateData().map((elements, key) => (
          <AbacusCol key={key}>
            {elements.map((type, key2) => (
              <AbacusSvg key={key2} type={type} />
            ))}
          </AbacusCol>
        ))}
      </AbacusWrapper>
    </>
  );
};

const QuestionSum = ({ data }) => {
  return (
    <>
      <h4>
        <Trans>Add</Trans>
      </h4>
      <ul>
        {data.dataValue &&
          data.dataValue.map((value, key) => <li key={key}>{value}</li>)}
      </ul>
    </>
  );
};

const QuestionMultiply = ({ data }) => {
  return (
    <>
      <h4>
        <Trans>Multiplication</Trans>
      </h4>
      <ul>
        {data.dataValue &&
          data.dataValue.map((value, key) => <li key={key}>{value}</li>)}
      </ul>
    </>
  );
};

const QuestionDivide = ({ data }) => {
  return (
    <>
      <h4>
        <Trans>Divide</Trans>
      </h4>
      <ul>
        {data.dataValue &&
          data.dataValue.map((value, key) => <li key={key}>{value}</li>)}
      </ul>
    </>
  );
};

const SpeedInputs = ({ name, value, onChange }) => {
  const keys = [1, 2, 3];
  const defaultValues = {};
  keys.forEach((key) => {
    defaultValues[key] = [];
  });

  const [values, setValues] = useState(defaultValues);
  const handleChange = (e, key, type) => {
    let tmp = { ...values };
    tmp[key][type] = e.target.value;
    setValues(tmp);
    onChange({
      target: {
        name: name,
        value: JSON.stringify(tmp),
      },
    });
  };

  useEffect(() => {
    try {
      setValues(JSON.parse(value));
    } catch (e) {}
  }, [value]);

  return (
    <>
      {keys.map((i) => (
        <SpeedGroup key={i}>
          <h5>{i}.</h5>
          <div>
            <FaCalendarAlt />
            <input
              type="text"
              placeholder="Data"
              name={name}
              value={values[i][0] || ""}
              onChange={(e) => handleChange(e, i, 0)}
            />
          </div>
          <div>
            <FaClock />
            <input
              type="text"
              placeholder="Czas"
              name={name}
              value={values[i][1] || ""}
              onChange={(e) => handleChange(e, i, 1)}
            />
          </div>
        </SpeedGroup>
      ))}
    </>
  );
};

const QuestionMultiplyTable = ({ data }) => {
  return (
    <h4>
      <Trans>Multiplication table</Trans>
    </h4>
  );
};

const MultiplyTable = ({ name, value, onChange }) => {
  const max = 9;
  const [valueArray, setValueArray] = useState(Array(max * max).fill(""));

  useEffect(() => {
    if (value.split(",").length === max * max) {
      setValueArray(value.split(","));
    }
  }, [value]);

  const generateTable = () => {
    const result = [];
    for (let x = 0; x < max; x++) {
      result[x] = [];
      for (let y = 0; y < max; y++) {
        result[x].push(y);
      }
    }
    return result;
  };

  const handleChange = (e, i1, i2) => {
    const key = i2 + i1 * max;
    const data = [
      ...valueArray.slice(0, key),
      e.target.value,
      ...valueArray.slice(key + 1),
    ];
    setValueArray(data);
    onChange({
      target: {
        name: name,
        value: data.join(","),
      },
    });
  };

  return (
    <>
      <TableStyled>
        <tbody>
          <tr>
            <td className="title-left"></td>
            {generateTable().map((data, i) => (
              <td key={i} className="title-top">
                {i + 1}
              </td>
            ))}
          </tr>
          {generateTable().map((data, i1) => (
            <tr key={i1}>
              <td className="title-left">{i1 + 1}</td>
              {data.map((i2) => (
                <td key={i2}>
                  <input
                    type="tel"
                    maxLength={2}
                    value={valueArray[i2 + i1 * max]}
                    onChange={(e) => handleChange(e, i1, i2)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </TableStyled>
    </>
  );
};

const AbacusSelect = styled.div`
  cursor: pointer;
  opacity: 0.4;

  ${(props) =>
    props.selected &&
    css`
      opacity: 1;
    `}

  &:hover {
    svg {
      polygon {
        fill: #ff5800 !important;
      }
    }
  }
`;

const AbacusSelectorCol = ({ value, handleClick }) => {
  return (
    <AbacusCol>
      <AbacusSelect
        selected={value >= 5 && value <= 9}
        onClick={() => handleClick(5)}
      >
        <AbacusSvg type="empty" />
      </AbacusSelect>
      <AbacusSvg type="line" />
      <AbacusSelect
        selected={(value >= 1 && value <= 4) || (value >= 6 && value <= 9)}
        onClick={() => handleClick(value >= 5 ? 6 : 1)}
      >
        <AbacusSvg type="empty" />
      </AbacusSelect>
      <AbacusSelect
        selected={(value >= 2 && value <= 4) || (value >= 7 && value <= 9)}
        onClick={() => handleClick(value >= 5 ? 7 : 2)}
      >
        <AbacusSvg type="empty" />
      </AbacusSelect>
      <AbacusSelect
        selected={(value >= 3 && value <= 4) || (value >= 8 && value <= 9)}
        onClick={() => handleClick(value >= 5 ? 8 : 3)}
      >
        <AbacusSvg type="empty" />
      </AbacusSelect>
      <AbacusSelect
        selected={(value >= 4 && value <= 4) || (value >= 9 && value <= 9)}
        onClick={() => handleClick(value >= 5 ? 9 : 4)}
      >
        <AbacusSvg type="empty" />
      </AbacusSelect>
    </AbacusCol>
  );
};

const AbacusSelector = ({ name, value, solution, onChange }) => {
  const handleClick = (val, key) => {
    let dig = parseInt(String(value)[key]);
    if (parseInt(val) === 5 && parseInt(dig) > 5) {
      dig = dig - 5;
    } else if (parseInt(val) === 5 && parseInt(dig) < 5) {
      dig = dig + 5;
    } else if (parseInt(val) === 1 && parseInt(dig) === 1) {
      dig = 0;
    } else if (parseInt(val) === 5 && parseInt(dig) === 5) {
      dig = 0;
    } else if (parseInt(val) === 6 && parseInt(dig) === 6) {
      dig = 0;
    } else {
      dig = val;
    }
    dig > 9 && (dig = 9);
    dig < 0 && (dig = 0);

    let result = String(value).split("");
    result[key] = dig;
    value = result.join("");

    onChange({
      target: {
        name,
        value,
      },
    });
  };

  return (
    <div>
      <AbacusWrapper>
        {String(solution)
          .split("")
          .map((v, key) => (
            <AbacusSelectorCol
              key={key}
              handleClick={(v) => handleClick(v, key)}
              value={String(value)[key] || ""}
            />
          ))}
      </AbacusWrapper>
    </div>
  );
};

const QuestionAbacusSelector = ({ data }) => {
  return (
    <>
      <h4>{data.dataSolution}:</h4>
    </>
  );
};

const DisableSelection = styled.div`
  user-select: none;
`;

const QuestioRewrite = ({ data }) => {
  return (
    <DisableSelection>
      <h4>{data.dataSolution}</h4>
    </DisableSelection>
  );
};

const QuestionSpeed = ({ data }) => {
  return (
    <>
      <h4>
        <Trans>Speed</Trans>
      </h4>
      {data.dataValue && (
        <>
          <p>
            {data.dataValue.start && data.dataValue.start.join(" ")}{" "}
            {data.dataValue.end && <>... {data.dataValue.end.join(" ")}</>}
          </p>
          {data.dataValue.answer && <p>Odpowiedź: {data.dataValue.answer}</p>}
        </>
      )}
    </>
  );
};

const QuestionSpeed2 = ({ data }) => {
  return (
    <>
      {data.dataValue && (
        <>{data.dataValue.number && <p>{data.dataValue.number}</p>}</>
      )}
    </>
  );
};

const QuestionType = ({ data, name, onChange, value }) => {
  if (data.dataType === "abacus") {
    return <QuestionAbacus data={data} />;
  } else if (data.dataType === "sum") {
    return <QuestionSum data={data} />;
  } else if (data.dataType === "multiply") {
    return <QuestionMultiply data={data} />;
  } else if (data.dataType === "divide") {
    return <QuestionDivide data={data} />;
  } else if (data.dataType === "abacusSelector") {
    return <QuestionAbacusSelector data={data} />;
  } else if (data.dataType === "multipleTable") {
    return <QuestionMultiplyTable data={data} />;
  } else if (data.dataType === "rewrite") {
    return <QuestioRewrite data={data} />;
  } else if (data.dataType === "speed") {
    return <QuestionSpeed data={data} />;
  } else if (data.dataType === "speed2") {
    return <QuestionSpeed2 data={data} />;
  } else {
    return <div style={{ height: "100px", textAlign: "center" }}></div>;
  }
};

const AbacusButton = styled.button`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 5px 20px;
  padding-bottom: 15px;
  border-bottom: 0;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #bbbbbb;
  border-bottom: 0;
`;

const AbacusContent = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  width: 95%;
  max-width: 600px;
  padding: 10px;

  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #bbbbbb;
  border-bottom: 0;
`;

const AbacusClose = styled.button`
  float: right;
  padding: 5px;
  margin-top: -6px;
`;

const Patterns = styled.div`
  text-align: center;
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 29px;
  font-size: 14px;
`;

const Questions = ({ isTeacher }) => {
  let { childId, childHash, pageId, bookId } = useParams();
  const [page, setPage] = useState(null);
  const [groups, setGroups] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [abacusOpen, setAbacusOpen] = useState(false);
  const [values, setValues] = useState({});

  const fetchValues = ({ groups }) => {
    let result = {};
    Object.keys(groups).forEach((name) => {
      groups[name].forEach((question) => {
        result[question.id] = "";
        if (question.answer) {
          result[question.id] = question.answer.value;
        }
      });
    });
    setValues(result);
  };

  const handleInput = (value, questionId) => {
    debounceEmitQuestion({
      childId,
      bookId,
      pageId,
      questionId,
      value,
    });
    debounceFetchAnswers({ childId, pageId });
  };

  const newHandleInput = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    handleInput(e.target.value, e.target.name);
  };

  useEffect(() => {
    const fetchPage = async ({ bookId, pageId }) => {
      const { data } = await Api.get(
        `api/book/${bookId}/page/${pageId}?childId=${childId}`
      );
      setPage(data.page);
      setGroups(data.groups);
      setPrevPage(data.prevPage);
      setNextPage(data.nextPage);
      fetchValues(data);
    };

    socket.emit("data", ["questions"]);

    fetchPage({ bookId, pageId });
    fetchAnswers({ childId, pageId });
  }, [childId, bookId, pageId]);

  useEffect(() => {
    const onUpdateQuestions = (data) => {
      if (
        data.bookId !== bookId ||
        data.childId !== childId ||
        data.pageId !== pageId
      ) {
        return;
      }
      console.log("onUpdateQuestions", data);

      setValues({
        ...values,
        [data.questionId]: data.value,
      });
    };
    socket.on("onUpdateQuestions", onUpdateQuestions);
    return () => {
      socket.off("onUpdateQuestions", onUpdateQuestions);
    };
  }, [values, childId, bookId, pageId]);

  const fetchAnswers = async ({ childId, pageId }) => {
    const { data } = await Api.get(
      `api/child/${childId}/page/${pageId}/calculateAnswers`
    );
    setAnswers(data);
  };

  const emitQuestion = (data) => {
    socket.emit("question", data);
  };
  const debounceEmitQuestion = useCallback(debounce(emitQuestion, 100), []);
  const debounceFetchAnswers = useCallback(debounce(fetchAnswers, 200), []);

  const { i18n, t } = useTranslation();
  const history = useHistory();

  return (
    <>
      {page && (
        <>
          <Container main background={page.color}>
            <Page>
              <Header>
                <div>
                  <Link to={`/child/${childId}/${childHash}/book/${bookId}`}>
                    <Trans>Back</Trans>
                  </Link>
                </div>
                <h3>
                  <Trans>Page</Trans> {page.name}
                </h3>
                <div>
                  <Link
                    className={!prevPage ? `disabled` : ``}
                    to={
                      prevPage
                        ? `/child/${childId}/${childHash}/book/${bookId}/page/${prevPage.id}`
                        : `#`
                    }
                  >
                    <FaAngleLeft />
                  </Link>

                  <Link
                    className={!nextPage ? `disabled` : ``}
                    to={
                      nextPage
                        ? `/child/${childId}/${childHash}/book/${bookId}/page/${nextPage.id}`
                        : `#`
                    }
                  >
                    <FaAngleRight />
                  </Link>
                </div>
              </Header>

              {answers && (
                <Answers>
                  <AnswersProgress
                    value={(answers.answers / answers.questions) * 100}
                  />
                  <p>
                    {answers.answers}/{answers.questions}
                  </p>
                  <p>
                    <small style={{ color: "green" }}>
                      <FaCheckCircle />{" "}
                      {answers.validateResult.filter((v) => v.success).length}
                    </small>
                  </p>
                </Answers>
              )}

              {page[`description${i18n.language.toUpperCase()}`] && (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  <FaInfoCircle />{" "}
                  {page[`description${i18n.language.toUpperCase()}`]}
                </div>
              )}

              {groups && Object.keys(groups).length ? (
                Object.keys(groups).map((group, i) => (
                  <div key={i}>
                    <GroupTitle>{group}</GroupTitle>
                    <QuestionList>
                      {groups[group].map((question, key) => (
                        <React.Fragment key={question.id}>
                          {question.dataType === "speed2" &&
                            question.dataValue.description && (
                              <QuestionWrapper>
                                <h3>-</h3>
                                <Question>
                                  <Patterns>
                                    {question.dataValue.description}
                                  </Patterns>
                                </Question>
                              </QuestionWrapper>
                            )}
                          <QuestionWrapper
                            color={page.color}
                            full={
                              question.dataType === "multipleTable"
                                ? true
                                : false
                            }
                          >
                            <h3>{key + 1}</h3>
                            <Question
                              valid={
                                isTeacher &&
                                question.answer &&
                                question.answer.value === question.dataSolution
                              }
                              wrong={
                                isTeacher &&
                                question.answer &&
                                question.answer.value !== question.dataSolution
                              }
                            >
                              <QuestionType data={question} />

                              {(() => {
                                if (question.dataType === "abacusSelector") {
                                  return (
                                    <AbacusSelector
                                      name={question.id}
                                      value={values[question.id] || ""}
                                      onChange={newHandleInput}
                                      solution={question.dataSolution}
                                    />
                                  );
                                } else if (
                                  question.dataType === "multipleTable"
                                ) {
                                  return (
                                    <MultiplyTable
                                      name={question.id}
                                      value={values[question.id] || ""}
                                      onChange={newHandleInput}
                                      solution={question.dataSolution}
                                    />
                                  );
                                } else if (question.dataType === "rewrite") {
                                  return (
                                    <input
                                      type="text"
                                      placeholder={t(`Answer...`)}
                                      name={question.id}
                                      value={values[question.id] || ""}
                                      onChange={newHandleInput}
                                      autocomplete="off"
                                    />
                                  );
                                } else if (question.dataType === "speed") {
                                  return (
                                    <>
                                      <SpeedInputs
                                        name={question.id}
                                        value={values[question.id] || ""}
                                        onChange={newHandleInput}
                                        solution={question.dataSolution}
                                      />
                                    </>
                                  );
                                } else if (question.dataType === "speed2") {
                                  return (
                                    <>
                                      <input
                                        type="text"
                                        placeholder={t(`Answer...`)}
                                        name={question.id}
                                        value={values[question.id] || ""}
                                        onChange={newHandleInput}
                                        autocomplete="off"
                                      />
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <input
                                        type="text"
                                        placeholder={t(`Answer...`)}
                                        name={question.id}
                                        value={values[question.id] || ""}
                                        onChange={newHandleInput}
                                        autocomplete="off"
                                      />
                                    </>
                                  );
                                }
                              })()}
                            </Question>
                            {isTeacher ? (
                              <div
                                style={{ fontSize: "12px", marginTop: "5px" }}
                              >
                                <Trans>Solution</Trans>: {question.dataSolution}{" "}
                                <button onClick={() => history.go(0)}>
                                  <BiRefresh />
                                </button>
                              </div>
                            ) : null}
                          </QuestionWrapper>
                        </React.Fragment>
                      ))}
                    </QuestionList>
                  </div>
                ))
              ) : (
                <p>Empty</p>
              )}
            </Page>

            <AbacusButton onClick={() => setAbacusOpen(true)}>
              <GoGraph /> <Trans>Open Abacus</Trans>
            </AbacusButton>
            {abacusOpen && (
              <AbacusContent>
                <AbacusClose onClick={() => setAbacusOpen(false)}>
                  X
                </AbacusClose>
                <Abacus childId={childId} pageId={pageId} bookId={bookId} />
              </AbacusContent>
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default Questions;
