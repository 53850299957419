import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import Api from "../../../utils/Api";
import { Book, BookList } from "./BooksStyled";
import { Container } from "../../App/AppStyled";
import { Trans } from "react-i18next";

const Books = ({ oldTableURL, modules, stars }) => {
  let { url } = useRouteMatch();
  const [books, setBooks] = useState(null);

  useEffect(() => {
    const fetchBooks = async () => {
      let { data } = await Api.get(`api/books`);
      if (modules && modules.length) {
        data = data.filter((d) => {
          return modules.indexOf(String(d.id)) !== -1;
        });
      }
      setBooks(data);
    };
    fetchBooks();
  }, [modules]);

  return (
    <>
      {books && (
        <>
          <Container main>
            <BookList>
              {books.length ? (
                <>
                  {books.map((book) => (
                    <Book key={book.id} color={book.color}>
                      <Link to={`${url}/book/${book.id}`}>{book.number}</Link>
                      <h4>
                        <Trans>Module</Trans> {book.number}
                      </h4>
                    </Book>
                  ))}
                </>
              ) : (
                <p style={{ textAlign: "center" }}>
                  <Trans>Empty</Trans>
                </p>
              )}
            </BookList>

            {/* <div>
              <AdditionalsButton href={oldTableURL} target="_blank">
                <Trans>Additionals</Trans>
              </AdditionalsButton>
            </div> */}
            <div style={{ marginTop: "15px" }}>
              {[...Array(stars)].map((value, key) => (
                <svg
                  key={key}
                  style={{ width: "60px", height: "60px" }}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    fill="#ffef12"
                    stroke="#ffc600"
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                  ></path>
                </svg>
              ))}
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default Books;
