import styled from "styled-components";
import school from "../../../img/school.png";
import { darken, transparentize } from "polished";

const imageBackground = (color, image, hover = false) => {
  const color1 = transparentize(hover ? 0.4 : 0.6, darken(0, color));
  const color2 = transparentize(0.3, darken(0.5, color));

  return `linear-gradient(${color1}, ${color2}), url(${image})`;
};

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  h2 {
    margin: 10px 0;
  }

  a {
    background: white;
    color: #000;
    padding: 5px 10px;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    margin-right: 3px;

    &.disabled {
      background: #dcdcdc;
      color: #fff;
      cursor: not-allowed;
    }
  }
`;

export const PageList = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
`;

export const Page = styled.div`
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.15);
  border-radius: 2px;
  overflow: hidden;

  a {
    display: block;
    font-size: 30px;
    font-weight: bold;
    padding: 20%;
    text-align: center;

    background: ${(props) => props.color};
    background-image: ${(props) => imageBackground(props.color, school)};
    background-size: cover;
    color: #fff;
  }
`;

export const QuestionsCount = styled.div`
  text-align: center;
  font-size: 13px;
  background: ${(props) => props.color};
  color: #fff;

  p {
    margin: 0;
    padding: 4px;
  }
`;

export const QuestionsProgress = styled.div`
  background: #fff;
  position: relative;
  height: 5px;

  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${(props) => props.value}%;
    background: #ff8400;
  }
`;
