import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
  GlobalStyles,
  HeaderWrapper,
  ContentWrapper,
  Header,
  Container,
} from "./AppStyled";
import { socket } from "../../services/io";
import logo from "../../img/logo.png";
import Child from "../Child/Child";
import { BiLoader, BiError } from "react-icons/bi";

import { Trans, useTranslation } from "react-i18next";

import detectIframe from "../../utils/DetectIframe";

const App = () => {
  const { i18n } = useTranslation();
  const [connected, setConnected] = useState(false);

  const socketConnect = () => {
    setConnected(true);
  };

  const socketDisconnect = () => {
    setConnected(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    socket.connect();

    socket.on("connect", socketConnect);
    socket.on("disconnect", socketDisconnect);

    return () => {
      socket.off("connect", socketConnect);
      socket.off("disconnect", socketDisconnect);
    };
  });

  return (
    <>
      <GlobalStyles />
      {connected ? (
        <BrowserRouter>
          {!detectIframe() && (
            <HeaderWrapper>
              <Container>
                <Header>
                  <img src={logo} alt="Logo" />
                  <div style={{ fontSize: "12px" }}>
                    <button
                      onClick={() => changeLanguage("en")}
                      style={{ marginRight: "10px" }}
                    >
                      EN
                    </button>
                    <button
                      onClick={() => changeLanguage("pl")}
                      style={{ marginRight: "10px" }}
                    >
                      PL
                    </button>
                    <button onClick={() => changeLanguage("nor")}>NOR</button>
                  </div>
                </Header>
              </Container>
            </HeaderWrapper>
          )}

          <ContentWrapper addPadding={!detectIframe()}>
            <Switch>
              <Route path="/child/:childId/:childHash">
                <Child />
              </Route>
              <Route path="/">
                <div style={{ textAlign: "center" }}>
                  <h3>
                    <BiError />
                    Erorr 404
                  </h3>
                  <p>Page not found</p>
                </div>
              </Route>
            </Switch>
          </ContentWrapper>
        </BrowserRouter>
      ) : (
        <div style={{ margin: "20px", textAlign: "center" }}>
          <h3>
            <BiLoader /> <Trans>Loading</Trans>...
          </h3>
          <p>
            (<Trans>Refresh if too long</Trans>)
          </p>
        </div>
      )}
    </>
  );
};

export default App;
