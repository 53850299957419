import styled, { createGlobalStyle, css } from "styled-components";
import school from "../../img/school.png";
import BackgroundImage from "../../utils/BackgroundImage";

export const HeaderWrapper = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 0px 0px 10px #04040414;
  z-index: 999;
`;

export const ContentWrapper = styled.div`
  padding-top: 15px;
  ${(props) =>
    props.addPadding &&
    css`
      margin-top: 60px;
    `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: #fff;

  img {
    display: block;
    height: 50px;
  }

  a {
    padding-top: 10px;
  }
`;

export const Container = styled.div`
  padding: 0 10vw;

  ${(props) =>
    props.background &&
    css`
      background: ${(props) => props.background};
      background-image: ${(props) =>
        BackgroundImage(props.background, school, true)};
      background-size: 500px 500px;
      background-repeat: repeat;
      margin-top: -70px;
      padding-top: 75px;
    `}

  ${(props) =>
    props.main &&
    css`
      /* padding-top: 70px !important; */
      padding-bottom: 50px;
    `}

  @media (max-width: 800px) {
    padding-left: 10px;
    padding-right: 10px;
    /* padding: 0 20px; */
  }
`;

export const GlobalStyles = createGlobalStyle`

  *, :after, :before {
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    background: #f3f3f3;
    margin: 0;
  }

  button {
    border: none;
    background: none;
    outline: none;
    padding: 0;
    cursor: pointer;
  }

  a {
    color: #0065ff;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
  }

  svg {
    position: relative;
    top: 2px;
  }
`;
