import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Route,
  Switch,
  useParams,
  useRouteMatch,
  Link,
} from "react-router-dom";
import Books from "./Books/Books";
import Pages from "./Pages/Pages";
import Questions from "./Questions/Questions";
import { socket } from "../../services/io";
import ApiSystem from "../../utils/ApiSystem";
import { FaUserCircle } from "react-icons/fa";
import { BiError } from "react-icons/bi";
import { Trans } from "react-i18next";
import detectIframe from "../../utils/DetectIframe";

const StudentName = styled.div`
  display: block;
  color: red;
  text-align: center;

  h4 {
    display: inline-block;
    background: #fff;
    padding: 5px;
    color: #000;
    border-radius: 6px;
    font-weight: 400;
    margin: 15px;
  }
`;

const Child = () => {
  let { childId, childHash } = useParams();
  let { path } = useRouteMatch();
  const [studentData, setStudentData] = useState(null);
  const [error, setError] = useState(null);
  const [isTeacher, setIsTeacher] = useState(false);

  useEffect(() => {
    const fetchStudentData = async () => {
      const { data } = await ApiSystem(
        `/json/child-data/${childId}/${childHash}`
      );
      if (data.error) {
        setError("Student not found");
        return;
      }
	if(!data.table_active){
		return setError('Disabled');
	}
      setStudentData(data);
    };
    fetchStudentData();

    //setIsTeacher(true);

    if (typeof window === "object") {
      window.onmessage = function (e) {
        if (e.data === "isTeacher") {
          setIsTeacher(true);
        }
      };
    }
  }, [childId, childHash]);

  useEffect(() => {
    if (childId && childHash && studentData) {
      //console.log("join");
      socket.emit("join", childId);
    }
  }, [childId, childHash, studentData]);

  return (
    <>
      {studentData ? (
        <>
          <StudentName>
            {!detectIframe() && (
              <Link to={`/child/${childId}/${childHash}`}>
                <h4>
                  <FaUserCircle /> {studentData.name}
                </h4>
              </Link>
            )}
          </StudentName>
          <Switch>
            <Route path={`${path}/book/:bookId/page/:pageId`}>
              <Questions isTeacher={isTeacher} />
            </Route>
            <Route path={`${path}/book/:bookId`}>
              <Pages />
            </Route>
            <Route path={path}>
              <Books
                oldTableURL={studentData.old_table_url}
                modules={studentData.table_books}
                stars={studentData.table_stars}
              />
            </Route>
          </Switch>
        </>
      ) : (
        error && (
          <div style={{ textAlign: "center" }}>
            <h3>
              <BiError />
              <Trans>Error</Trans>
            </h3>
            <p><Trans>{error}</Trans></p>
          </div>
        )
      )}
    </>
  );
};
export default Child;
