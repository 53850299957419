import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {},
  },
  pl: {
    translation: {
      Back: "Powrót",
      "Open Abacus": "Otwórz Abacus",
      Module: "Moduł",
      Page: "Strona",
      Loading: "Ładowanie",
      "Refresh if too long": "Odswież stronę jeżeli trwa to zbyt długo",
      Error: "Błąd",
      Additionals: "Dodatki",
      Add: "Dodawanie",
      Multiplication: "Mnożenie",
      "Multiplication table": "Tabliczka mnożenia",
      Speed: "Prędkość",
      Directly: "Bezpośrednio",
      Empty: "Brak wyników",
      Solution: "Rozwiązanie",
      Divide: "Dzielenie",
      "Answer...": "Odpowiedź...",
	"Disabled": "Wyłączone"
    },
  },
  nor: {
    translation: {
      Back: "Tilbake",
      "Open Abacus": "Kuleramme",
      Module: "Modul",
      Page: "Side",
      Loading: "Landing",
      "Refresh if too long": "Oppdater hvis det er for lenge",
      Error: "Feil",
      Additionals: "Tilbehør",
      Add: "Addisjon",
      Multiplication: "Multiplikasjon",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    whitelist: ["en", "pl", "nor"],
    // lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    detection: {
      order: ["localStorage", "navigator"],
    },
  });

export default i18n;
